
import { uniqueId } from 'lodash'
import { useFormat } from '@/utils/format'
import { Activity, Plan } from '@/api/interfaces'
import { isBefore, isEqual, isAfter } from 'date-fns'
import { useActivityHelper } from './timetracking-helper'
import DateInput from '@/components/common/date-input.vue'
import { useFormatPlan } from '@/utils/format/format-plan'
import { useValidation, ValidationForm } from '@/utils/validation'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'
import { formatStringToNumber } from '@/utils/format/format-string-to-number'
import { computed, defineComponent, inject, PropType, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'activity-item',
  components: {
    DateInput,
    ConfirmDialog,
  },
  props: {
    activity: {
      type: Object as PropType<Activity>,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    plans: {
      type: Array as PropType<Plan[]>,
      default: () => [],
    },
  },
  emits: ['click', 'click:submit', 'click:cancel', 'click:delete'],
  setup(props, { root, emit }) {
    const { formatDateString, formatDecimal } = useFormat()
    const formId = uniqueId('activity-item-')
    const formRef = ref<ValidationForm | null>(null)
    const formValidate = ref(false)

    const activityInternal = ref({ ...props.activity })

    const getColorForDate = inject<(date: Date) => string>('getColorForDate', () => '')

    const { createDateFromString, getDateAsString } = useActivityHelper()

    function getColorForDateString(dateString: string) {
      const date = createDateFromString(dateString)
      return getColorForDate(date)
    }

    const plansFiltered = computed(() =>
      props.plans.filter((plan) => {
        return (
          !isAfter(createDateFromString(plan.from), createDateFromString(props.activity.date)) &&
          !isBefore(createDateFromString(plan.to), createDateFromString(props.activity.date))
        )
      })
    )

    const selectedPlan = computed(() => props.plans.find((plan) => plan.id === activityInternal.value.planId))

    const datePickerOptions = computed(() => {
      const today = new Date()
      const planMinDate = selectedPlan.value?.from ? createDateFromString(selectedPlan.value?.from) : today
      const planMaxDate = selectedPlan.value?.from ? createDateFromString(selectedPlan.value?.to) : today
      return {
        events: getColorForDateString,
        min: getDateAsString(isAfter(planMinDate, today) ? today : planMinDate),
        max: getDateAsString(isAfter(planMaxDate, today) ? today : planMaxDate),
      }
    })

    watch(
      () => props.activity,
      (value) => (activityInternal.value = { ...value })
    )

    const { formatPlan } = useFormatPlan()

    function clickSelf() {
      emit('click', { ...activityInternal.value })
    }

    function clickSubmit() {
      if (!formRef.value?.validate()) return
      emit('click:submit', activityInternal.value)
    }

    function clickCancel() {
      activityInternal.value = { ...props.activity }
      emit('click:cancel', { ...activityInternal.value })
    }

    const showConfirmDialog = ref(false)

    function onDelete() {
      showConfirmDialog.value = true
    }

    function onHoursInput(value: string | null) {
      if (value) activityInternal.value.hours = formatStringToNumber(value)
    }

    function clickDelete() {
      emit('click:delete', { ...activityInternal.value })
    }

    function validateDate() {
      if (!selectedPlan.value) return false

      const before = isBefore(
        createDateFromString(activityInternal.value.date),
        createDateFromString(selectedPlan.value.to)
      )

      const equal =
        isEqual(createDateFromString(activityInternal.value.date), createDateFromString(selectedPlan.value.from)) ||
        isEqual(createDateFromString(activityInternal.value.date), createDateFromString(selectedPlan.value.to))

      const after = isAfter(
        createDateFromString(activityInternal.value.date),
        createDateFromString(selectedPlan.value.from)
      )

      return (before && after) || equal || root.$t('form.validation.date')
    }

    return {
      formId,
      formRef,
      formValidate,
      plansFiltered,
      activityInternal,
      datePickerOptions,
      showConfirmDialog,

      onDelete,
      clickSelf,
      formatPlan,
      clickCancel,
      clickDelete,
      clickSubmit,
      onHoursInput,
      validateDate,
      formatDecimal,
      formatDateString,
      ...useValidation(),
      getColorForDateString,
    }
  },
})
