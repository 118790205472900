import { Activity } from '@/api/interfaces'
import { useAuthGetters } from '@/store'

const { currentUser } = useAuthGetters()

const createDateFromString = (dateString: string) => {
  const [year, month, day] = dateString.split('-').map((s) => Number(s))
  return new Date(year, month - 1, day)
}

const getHourColor = (hours: number) => {
  return hours >= 10 ? '#ff956e' : hours >= 8 ? '#9ed16a' : '#51c0ef'
}

const getTotalHoursForGroup = (group: Activity[]) => {
  return group.reduce((sum, activity) => (sum += activity.hours), 0)
}

const getColorForGroup = (group: Activity[]) => {
  const hours = getTotalHoursForGroup(group)
  return getHourColor(hours)
}

const getDayOfWeek = (date: Date, format: Intl.DateTimeFormatOptions['weekday'] = 'long') => {
  return date.toLocaleDateString(currentUser.value.locale, { weekday: format })
}

const getDateAsString = (date: Date) => {
  const timeZoneOffset = date.getTimezoneOffset() * 60000
  const dateNormalized = new Date(date.getTime() - timeZoneOffset)
  return dateNormalized.toISOString().split('T')[0]
}

export const useActivityHelper = () => {
  return {
    createDateFromString,
    getDayOfWeek,
    getColorForGroup,
    getTotalHoursForGroup,
    getDateAsString,
  }
}
