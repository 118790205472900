
import { computed, defineComponent, onMounted, ref, watch, provide } from '@vue/composition-api'
import { useActivityRepository } from '@/api/repository/activity-repository'
import { Activity, ActivityInput } from '@/api/interfaces'
import { cloneDeep } from 'lodash'
import { usePlanRepository } from '@/api/repository/plan-repository'
import { useMonthlyHoursRepository } from '@/api/repository/monthly-hours-repository'
import { startOfToday, lastDayOfMonth, setDate, getMonth, isBefore, isAfter, getYear } from 'date-fns'
import { useFormat } from '@/utils/format'
import ActivityForm from '@/components/timetracking/activity-form.cmp.vue'
import ActivityItemGroup from '@/components/timetracking/activity-item-group.cmp.vue'
import MonthlyHoursMenu from '@/components/timetracking/monthly-hours-menu.cmp.vue'
import DayPicker from '@/components/timetracking/day-picker.cmp.vue'
import { useActivityHelper } from '@/components/timetracking/timetracking-helper'
import { formatDate } from '@/utils/format/format-date'
import { useAuthGetters } from '@/store'

export default defineComponent({
  name: 'timetracking-overview',
  components: {
    ActivityForm,
    ActivityItemGroup,
    MonthlyHoursMenu,
    DayPicker,
  },
  setup() {
    const { formatDecimal } = useFormat()
    const activityRepository = useActivityRepository()
    const { getAll, data, isLoading: activityIsLoading } = activityRepository.useGetAll()
    const activityToEdit = ref<Activity | null>(null)

    const { currentUser } = useAuthGetters()

    const date = ref(startOfToday())

    const monthAndYearString = computed(() => {
      if (!currentUser) return
      return formatDate(date.value, { format: { month: 'long', year: 'numeric' } })
    })

    const { createDateFromString, getColorForGroup, getDateAsString } = useActivityHelper()

    const planRepository = usePlanRepository()
    const { getAll: getAllPlans, data: plans, isLoading: planIsLoading } = planRepository.useGetAll()
    const planSearchInput = ref('')
    watch(
      planSearchInput,
      (name) => {
        if (!currentUser.value.id) return
        getAllPlans({ params: { name, userId: currentUser.value.id } })
      },
      {
        immediate: true,
      }
    )

    const plansFiltered = computed(() =>
      plans.value.filter((plan) => {
        return (
          !isAfter(createDateFromString(plan.from), date.value) &&
          !isBefore(createDateFromString(plan.to), date.value)
        )
      })
    )

    async function fetchActivities() {
      const dateFromDate = setDate(date.value, 1)
      const dateToDate = lastDayOfMonth(dateFromDate)
      const dateFrom = getDateAsString(dateFromDate)
      const dateTo = getDateAsString(dateToDate)
      if (!currentUser.value.id) return
      await getAll({ params: { dateFrom, dateTo, size: 9999, userId: currentUser.value.id } })
    }

    watch(date, (current, prev) => {
      if (getMonth(current) === getMonth(prev)) return
      fetchResources()
    })

    async function fetchPlans() {
      if (!currentUser.value.id) return
      await getAllPlans({ params: { size: 9999, userId: currentUser.value.id } })
    }

    async function fetchResources() {
      fetchActivities()
      fetchPlans()
      fetchMonthlyHours()
    }

    interface ActivitiesGrouped {
      [key: string]: Activity[]
    }

    const activitiesGroupedByDay = computed(() => {
      const copy = cloneDeep(data.value)
      const sorted = copy.sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1))
      return sorted.reduce<ActivitiesGrouped>((results, activity) => {
        const group: Activity[] = results[activity.date] ?? []
        group.push(activity)
        results[activity.date] = group
        return results
      }, {})
    })

    const { useGetAll } = useMonthlyHoursRepository()

    const { getAll: getMonthlyHours, data: monthlyHoursList } = useGetAll()

    function fetchMonthlyHours() {
      if (!currentUser.value.id) return
      getMonthlyHours({
        params: { year: getYear(date.value), month: getMonth(date.value) + 1, userId: currentUser.value.id },
      })
    }

    const monthlyHours = computed(() => (monthlyHoursList.value.length > 0 ? monthlyHoursList.value[0].hours : 0))

    function getColorForDate(date: Date) {
      const dateString = getDateAsString(date)
      const group = activitiesGroupedByDay.value[dateString]
      if (!group) return
      return getColorForGroup(group)
    }

    provide('getColorForDate', getColorForDate)

    function onClickActivity(activity: Activity) {
      activityToEdit.value = activity
    }

    function onCancelActivity() {
      activityToEdit.value = null
    }

    async function onDeleteActivity(activity: Activity) {
      const { remove } = activityRepository.useRemove()
      await remove(activity.id)
      fetchResources()
    }

    async function clickSubmit(activity: ActivityInput) {
      await saveActivity(activity)
    }

    async function saveActivity(activity: ActivityInput | Activity) {
      const { save } = activityRepository.useSave()
      await save(activity)
      activityToEdit.value = null
      fetchResources()
    }

    const pageIsLoading = computed(() => activityIsLoading.value || planIsLoading.value)

    onMounted(() => {
      fetchResources()
    })

    return {
      formatDecimal,

      date,
      monthAndYearString,
      createDateFromString,
      getColorForDate,

      data,
      activitiesGroupedByDay,
      onClickActivity,
      activityToEdit,
      onCancelActivity,
      onDeleteActivity,
      saveActivity,

      plans,
      plansFiltered,

      clickSubmit,

      pageIsLoading,

      monthlyHours,
    }
  },
})
