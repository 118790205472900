import { repository } from '@/plugins/repository'
import { MonthlyHourInput, MonthlyHour } from '../interfaces'

export const useMonthlyHoursRepository = () => {
  const version = 'v1'
  const resource = 'monthlyHours'
  const reportingRepository = repository<MonthlyHour, MonthlyHourInput>(version, resource)

  return {
    ...reportingRepository,
  }
}
