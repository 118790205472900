
import { Activity, Plan } from '@/api/interfaces'
import { useFormat } from '@/utils/format'
import { ValidationForm, workingTimeValidation } from '@/utils/validation'
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import ActivityItem from './activity-item.cmp.vue'
import { useActivityHelper } from './timetracking-helper'
import { useCreateWorkDay, useGetWorkDay } from '@/api/workDay'
import { mdiCheck, mdiAlertCircle } from '@mdi/js'
import { UserRoleNames } from '@/views/profile/profile-view.vue'

export default defineComponent({
  name: 'activity-item-group',
  components: {
    ActivityItem,
  },
  props: {
    activities: {
      type: Array as PropType<Activity[]>,
      required: true,
    },
    activityToEdit: {
      type: null,
      required: true,
    },
    date: {
      type: Date,
      required: true,
    },
    plans: {
      type: Array as PropType<Plan[]>,
      required: true,
    },
  },
  emits: ['click:activity', 'click:submit', 'click:cancel', 'click:delete'],
  setup(props, { emit, root }) {
    function onClickActivity(activity: Activity) {
      emit('click:activity', { ...activity })
    }
    function onClickSubmit(activity: Activity) {
      emit('click:submit', { ...activity })
    }

    function onClickCancel(activity: Activity) {
      emit('click:cancel', { ...activity })
    }

    function onClickDelete(activity: Activity) {
      emit('click:delete', { ...activity })
    }

    const updateWorkingTimeForm = ref<ValidationForm | null>(null)
    const { createWorkDay } = useCreateWorkDay()
    const { getWorkDay, data: workDayData } = useGetWorkDay()
    const { getDateAsString, getTotalHoursForGroup } = useActivityHelper()

    const currentUser = computed(() => root.$store.state.auth.currentUser)
    const currentUserRolesNames: string[] = currentUser.value.roles.map((role) => role.name)

    const totalHoursForGroup = computed(() => getTotalHoursForGroup(props.activities))

    const workingTimesBegin = ref<string>('')
    const workingTimesEnd = ref<string>('')
    const workingBreakTime = ref<number>(0)

    const isFormFieldDisabled = ref<boolean>(true)

    const dateAsString: string = getDateAsString(props.date)

    function setWorkDayData() {
      if (workDayData.value[0]?.begin === null || workDayData.value[0]?.end === null) {
        workingTimesBegin.value = 'null'
        workingTimesEnd.value = 'null'
      } else {
        const { begin, end, breakTime } = workDayData.value[0]

        workingTimesBegin.value = begin
        workingTimesEnd.value = end
        workingBreakTime.value = breakTime
      }
    }

    async function init() {
      if (!currentUserRolesNames.includes(UserRoleNames.Freelancer)) {
        await getWorkDay(currentUser.value.id, dateAsString)

        setWorkDayData()
      }
    }

    init()

    watch(totalHoursForGroup, () => {
      init()
    })

    async function saveWorkingTime() {
      if (updateWorkingTimeForm.value?.validate()) {
        await createWorkDay(currentUser.value.id, dateAsString, workingTimesBegin.value, workingTimesEnd.value)
        init()
        isFormFieldDisabled.value = true
      }
    }

    return {
      onClickActivity,
      onClickSubmit,
      onClickCancel,
      onClickDelete,
      ...useActivityHelper(),
      ...useFormat(),
      workingTimesBegin,
      workingTimesEnd,
      workingTimeValidation,
      saveWorkingTime,
      updateWorkingTimeForm,
      workingBreakTime,
      mdiCheck,
      mdiAlertCircle,
      currentUserRolesNames,
      dateAsString,
      totalHoursForGroup,
      isFormFieldDisabled,
      UserRoleNames,
    }
  },
})
