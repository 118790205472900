
import { Activity } from '@/api/interfaces'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'monthly-hours-menu',
  props: {
    activities: {
      type: Array as PropType<Activity[]>,
      required: true,
    },
    hours: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    interface ActivitiesGrouped {
      [key: string]: Activity[]
    }

    const groupedByProject = computed(() =>
      props.activities.reduce<ActivitiesGrouped>((results, activity) => {
        const group: Activity[] = results[activity.projectId] ?? []
        group.push(activity)
        results[activity.projectId] = group
        return results
      }, {})
    )

    function getHoursForGroup(group: Activity[]) {
      return group.reduce((sum, activity) => (sum += activity.hours), 0)
    }

    const totalHours = computed(() => props.activities.reduce((sum, activity) => (sum += activity.hours), 0))

    return {
      groupedByProject,
      getHoursForGroup,
      totalHours,
    }
  },
})
