import { useAxios, useAxiosPaginated } from '@/plugins/axios'

const version = 'v1'

export interface WorkDay {
  userId: number
  dateWork: string
  begin: string
  end: string
}

export const useGetWorkDay = () => {
  const axios = useAxiosPaginated({
    method: 'get',
    url: `${version}/workDays`,
  })

  const getWorkDay = (userId?: number, dateWorkDay?: string) =>
    axios.exec({
      params: {
        userId,
        dateWorkDay,
        size: 9999,
      },
    })

  return {
    ...axios,
    getWorkDay,
  }
}

export const useCreateWorkDay = () => {
  const axios = useAxios<WorkDay>({
    method: 'post',
    url: `${version}/workDays`,
  })

  const createWorkDay = (userId: number, dateWork: string, begin: string, end: string) =>
    axios.exec({
      data: {
        userId,
        dateWork,
        begin,
        end,
      },
    })

  return {
    ...axios,
    createWorkDay,
  }
}
