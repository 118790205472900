
import { format } from 'date-fns'
import { uniqueId } from 'lodash'
import { Plan } from '@/api/interfaces'
import { useFormatPlan } from '@/utils/format/format-plan'
import { useValidation, ValidationForm } from '@/utils/validation'
import { defineComponent, ref, PropType, watch } from '@vue/composition-api'
import { useActivityRepository } from '@/api/repository/activity-repository'
import { formatStringToNumber } from '@/utils/format/format-string-to-number'

export default defineComponent({
  name: 'activity-form',
  props: {
    date: {
      type: Date,
      required: true,
    },
    plans: {
      type: Array as PropType<Plan[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formId = uniqueId('activity-form-')
    const formRef = ref<ValidationForm | null>(null)
    const formValue = ref(false)

    const activityRepository = useActivityRepository()
    const activityInternal = ref(activityRepository.dummyActivityInput())

    const activeHour = ref()
    const { formatPlan } = useFormatPlan()

    const availableHours = [0.5, 1, 1.5, 2, 4, 6, 8]

    function clickSubmit() {
      if (!formRef.value?.validate()) return
      activeHour.value = undefined
      emit('click:submit', { ...activityInternal.value })

      formRef.value.reset()
    }

    function clickCancel() {
      formRef.value?.reset()
      activeHour.value = undefined
      emit('click:cancel')
    }

    function clickHourChip(hour: number) {
      activityInternal.value.hours = hour
    }

    function onHoursInput(value: string | null) {
      if (value) activityInternal.value.hours = formatStringToNumber(value)
    }

    watch(
      () => props.date,
      (date) => {
        activityInternal.value.date = format(date, 'yyyy-MM-dd')
      },
      {
        immediate: true,
      }
    )

    return {
      formId,
      formRef,
      formValue,
      activeHour,
      availableHours,
      activityInternal,
      formatPlan,
      clickSubmit,
      clickCancel,
      onHoursInput,
      clickHourChip,
      ...useValidation(),
    }
  },
})
