var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"activity-item-group"},[_c('v-col',{staticStyle:{"margin-top":"2rem"}},[_c('v-row',{attrs:{"margin":""}},[_c('v-col',{staticStyle:{"color":"#899da2","padding-bottom":"0px"},attrs:{"cols":"12","md":"2"}},[_vm._v(_vm._s(_vm.getDayOfWeek(_vm.date)))]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.currentUserRolesNames.includes(_vm.UserRoleNames.Freelancer) &&
            _vm.workingTimesBegin !== 'null' &&
            _vm.workingTimesEnd !== 'null'
          ),expression:"\n            !currentUserRolesNames.includes(UserRoleNames.Freelancer) &&\n            workingTimesBegin !== 'null' &&\n            workingTimesEnd !== 'null'\n          "}],ref:"updateWorkingTimeForm",attrs:{"id":("updateWorkingTime-" + _vm.dateAsString)},on:{"submit":function($event){$event.preventDefault();return _vm.saveWorkingTime.apply(null, arguments)}}},[_c('v-row',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.isFormFieldDisabled = false}}},[_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{staticStyle:{"font-size":"small","padding-bottom":"0px","margin":"0"},attrs:{"label":_vm.$t('form.field.from'),"dense":"","type":"time","rules":[_vm.workingTimeValidation(_vm.workingTimesBegin, _vm.workingTimesEnd)],"disabled":_vm.isFormFieldDisabled},model:{value:(_vm.workingTimesBegin),callback:function ($$v) {_vm.workingTimesBegin=$$v},expression:"workingTimesBegin"}})],1),_c('v-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{staticStyle:{"font-size":"small","padding-bottom":"0px","margin":"0"},attrs:{"label":_vm.$t('form.field.till'),"dense":"","type":"time","disabled":_vm.isFormFieldDisabled},model:{value:(_vm.workingTimesEnd),callback:function ($$v) {_vm.workingTimesEnd=$$v},expression:"workingTimesEnd"}})],1),_c('v-col',{staticStyle:{"display":"flex","justify-content":"flex-end"},attrs:{"cols":"3","md":"3"}},[(!_vm.isFormFieldDisabled)?_c('v-btn',{staticClass:"text-12 text-lowercase onPrimary--text primary darken-1",staticStyle:{"margin-left":"20px"},attrs:{"rounded":"","small":"","text":"","form":("updateWorkingTime-" + _vm.dateAsString),"type":"submit"}},[_vm._v(_vm._s(_vm.$t('btn.update'))+" ")]):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"flex-end"},attrs:{"cols":"9","md":"9"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                !_vm.currentUserRolesNames.includes(_vm.UserRoleNames.Freelancer) &&
                _vm.workingTimesBegin !== 'null' &&
                _vm.workingTimesEnd !== 'null'
              ),expression:"\n                !currentUserRolesNames.includes(UserRoleNames.Freelancer) &&\n                workingTimesBegin !== 'null' &&\n                workingTimesEnd !== 'null'\n              "}]},[(_vm.totalHoursForGroup >= 6 && _vm.totalHoursForGroup < 9 && _vm.workingBreakTime < 30)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"30rem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var props = ref.props;
              var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',props,false),on),[_vm._v(_vm._s(_vm.mdiAlertCircle))])]}}],null,false,3357315438)},[_c('span',[_vm._v(_vm._s(_vm.$t('timetracking.breakTime.warning.short'))+" ")])])],1):_vm._e(),(_vm.totalHoursForGroup >= 9 && _vm.workingBreakTime < 45)?_c('span',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"30rem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var props = ref.props;
              var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',props,false),on),[_vm._v(_vm._s(_vm.mdiAlertCircle))])]}}],null,false,3357315438)},[_c('span',[_vm._v(_vm._s(_vm.$t('timetracking.breakTime.warning.long'))+" ")])])],1):_vm._e(),_c('span',{staticStyle:{"color":"#899da2","padding-bottom":"0px","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('workDay.breakTime'))+" "+_vm._s(_vm.workingBreakTime)+" "+_vm._s(_vm.$t('units.time.minutes'))+" ")])])]),_c('v-col',{staticStyle:{"display":"flex","justify-content":"flex-end","padding-bottom":"0px"},attrs:{"cols":"3","md":"3"}},[_c('v-chip',{staticStyle:{"font":"normal normal bold 14px/18px Fira Sans"},attrs:{"color":_vm.getColorForGroup(_vm.activities),"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.formatDecimal(_vm.totalHoursForGroup, 1))+" "+_vm._s(_vm.$t('units.time.hour'))+" ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"margin-top":"0.5rem"}},_vm._l((_vm.activities),function(activity){return _c('v-row',{key:activity.id},[_c('v-col',[_c('ActivityItem',{attrs:{"activity":activity,"edit-mode":_vm.activityToEdit !== null && _vm.activityToEdit.id === activity.id,"plans":_vm.plans},on:{"click":_vm.onClickActivity,"click:submit":_vm.onClickSubmit,"click:cancel":_vm.onClickCancel,"click:delete":_vm.onClickDelete}})],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }