
import { computed, defineComponent, inject } from '@vue/composition-api'
import {
  addMonths,
  getDate,
  getWeek,
  setDate,
  isMonday,
  isWeekend,
  getDaysInMonth,
  nextMonday,
  isFuture,
} from 'date-fns'
import { enUS, de } from 'date-fns/locale'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { useAuthGetters } from '@/store'
import { useActivityHelper } from './timetracking-helper'

export default defineComponent({
  props: {
    date: {
      type: Date,
      required: true,
    },
  },
  emits: ['update:date'],
  setup(props, { emit }) {
    const days = computed(() => getDaysInMonth(props.date))
    const activeDay = computed(() => getDate(props.date))
    const activeCalendarWeek = computed(() => getWeek(props.date))

    const { currentUser } = useAuthGetters()
    const locale = currentUser.value.locale === 'de' ? de : enUS

    const { getDayOfWeek } = useActivityHelper()

    function clickChangeMonth(direction: number) {
      let date = setDate(addMonths(props.date, direction), 1)
      if (isFuture(date)) return
      if (isWeekend(date)) date = nextMonday(date)
      updateDate(date)
    }

    function clickDay(day: number) {
      if (isDayDisabled(day)) return
      const date = setDate(props.date, day)
      updateDate(date)
    }

    function showCalendarWeekForDay(day: number) {
      const date = setDate(props.date, day)
      return isMonday(date)
    }

    function isDayDisabled(day: number) {
      const date = setDate(props.date, day)
      return isWeekend(date) || isFuture(date)
    }

    function getCalendarWeekForDay(day: number) {
      const date = setDate(props.date, day)
      return getWeek(date, { locale, weekStartsOn: 1, firstWeekContainsDate: 1 })
    }

    function getDayOfWeekForDay(day: number) {
      const date = setDate(props.date, day)
      return getDayOfWeek(date, 'short')
    }

    const getColorForDate = inject<(date: Date) => string>('getColorForDate', () => '')

    function getColorForDay(day: number) {
      const date = setDate(props.date, day)
      return getColorForDate(date)
    }

    const showNextMonth = computed(() => {
      const date = setDate(addMonths(props.date, 1), 1)
      return !isFuture(date)
    })

    function updateDate(date) {
      emit('update:date', date)
    }

    return {
      days,
      activeDay,
      activeCalendarWeek,
      clickChangeMonth,
      clickDay,
      showCalendarWeekForDay,
      isDayDisabled,
      getCalendarWeekForDay,
      getDayOfWeekForDay,
      getColorForDay,
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
      },
      showNextMonth,
    }
  },
})
